import isSSR from "./is-ssr"

/**
 * Trigger an event
 */
function triggerEvent(eventName, detail) {
  if (!isSSR()) {
    console.log("event", eventName, detail)
    window.dispatchEvent(
      detail ? new CustomEvent(eventName, { detail }) : new Event(eventName)
    )
  }
}

export default triggerEvent
