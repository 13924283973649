import getIdleCallback from "../compatibility/get-idle-callback"

/**
 * Try to call a function when the javascript call stack is empty.
 */
function callWhenIdle(func, opts = { delay: 0 }) {
  let idleCallback = getIdleCallback()
  let { delay } = opts

  return setTimeout(() => idleCallback(func), delay)
}

export default callWhenIdle
