import React from "react"
import Anchor from "../../core/components/anchor"
import Button from "../../client/compositions/button"
import SiteLogo from "../../client/compositions/site-logo"
import { address } from "../../client/elements/contact-details"
import {
  pagesMenu,
  secondaryPagesMenu,
  socialNetworksMenu,
} from "../../client/elements/menus"
import { animateOut } from "../../client/timelines/hamburger"

import CloseButton from "../../client/assets/images/icons/close.inline.svg"

const hamburger = (
  <aside id="___hamburger" onClick={(e) => animateOut()}>
    <div className="clip-wrapper">
      <div
        className="inner"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          return false
        }}
      >
        <SiteLogo image="logo" className="not-mobile-logo" />
        <SiteLogo image="logotype" className="mobile-logo" />
        <div className="actions">
          <div className="close button">
            <CloseButton
              title="Close Menu"
              className="icon"
              onClick={(e) => animateOut()}
            />
          </div>
        </div>
        {pagesMenu}
        {secondaryPagesMenu}
        {address}
        {socialNetworksMenu}
        <div className="credit">
          Designed by <Anchor url="https://kolaps.com">KOLAPS</Anchor>
        </div>
        <div className="copyright">&copy; 2020 Invinity</div>
        <SiteLogo image="logomark" />
      </div>
    </div>
  </aside>
)

export default hamburger
