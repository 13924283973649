/**
 * @see <https://www.gatsbyjs.org/docs/browser-apis/>
 */

// WARNING
// Put app-wide event handlers in app-events.js

// handle event triggering
const { default: callWhenIdle } = require("./src/core/functions/call-when-idle")
const { default: triggerEvent } = require("./src/core/functions/trigger-event")

/**
 * When any gatsby event is detected, trigger two events on the window object
 *
 * 1. "eventName.gatsby"
 * 2. "afterEventName.gatsby" (called on first idle frame since event triggered)
 *
 * Where "eventName" is the name of the gatsby event.
 *
 * Prefer "afterEventName.gatsby" to improve performance.
 */
const handleGatsbyEvent = (onEventName, ...args) => {
  /** Remove the word "on" from the beginning of a string */
  const removeOnPrefix = (string) => string.replace(/^on/, "")
  /** Set first letter of string to lowercase */
  const firstLetterToLowerCase = (string) =>
    string.charAt(0).toLowerCase() + string.slice(1)

  const EventName = removeOnPrefix(onEventName)
  const eventName = firstLetterToLowerCase(EventName)

  triggerEvent(`${eventName}.gatsby`, ...args)
  callWhenIdle(() => triggerEvent(`after${EventName}.gatsby`, ...args))
}

/**
 * Called when the Gatsby browser runtime first starts.
 */
exports.onClientEntry = () => {
  handleGatsbyEvent("onClientEntry")
}

/**
 * Called when the initial (but not subsequent) render of Gatsby App is done on
 * the client.
 */
exports.onInitialClientRender = () => {
  handleGatsbyEvent("onInitialClientRender")
}

/**
 * Called when prefetching for a pathname is successful. Allows for plugins
 * with custom prefetching logic.
 */
exports.onPostPrefetchPathname = () => {
  handleGatsbyEvent("onPostPrefetchPathname")
}

/**
 * Called when changing location is started.
 */
exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  handleGatsbyEvent("onPreRouteUpdate", { location, prevLocation })
}

/**
 * Called when prefetching for a pathname is triggered. Allows for plugins with
 * custom prefetching logic.
 */
exports.onPrefetchPathname = ({ pathname, loadPage }) => {
  handleGatsbyEvent("onPrefetchPathname", { pathname, loadPage })
}

/**
 * Called when the user changes routes, including on the initial load of the app
 */
exports.onRouteUpdate = ({ location, prevLocation }) => {
  handleGatsbyEvent("onRouteUpdate", { location, prevLocation })
}

/**
 * Called when changing location is longer than 1 second.
 */
exports.onRouteUpdateDelayed = ({ location, action }) => {
  handleGatsbyEvent("onRouteUpdateDelayed", { location, action })
}

/**
 * Allows a plugin to influence scrolling behavior on navigation. Default
 * behavior is persisting last known scrolling positions and scrolling back to
 * them on navigation. Plugins can also override this and return an Array of
 * coordinates or an element name to scroll to.
 *
 * Should return either an [x, y] Array of coordinates to scroll to, a string
 * of the id or name of an element to scroll to, false to not update the scroll
 * position, or true for the default behavior.
 */
exports.shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  pathname,
  getSavedScrollPosition,
}) => true

/**
 * This is useful for setting wrapper components around pages that won’t get
 * unmounted on page changes. For setting Provider components, use
 * wrapRootElement.
 *
 * Note: There is an equivalent hook in Gatsby’s Browser API. It is recommended
 * to use both APIs together.
 */
exports.wrapPageElement = require("./src/client/elements/page-wrapper").default

/**
 * This is useful to set up any Provider components that will wrap your
 * application. For setting persistent UI elements around pages use
 * wrapPageElement.
 *
 * Note: There is an equivalent hook in Gatsby’s Browser API. It is recommended
 * to use both APIs together.
 */
exports.wrapRootElement = require("./src/client/elements/root-wrapper").default
