import { LINK_NAVIGATE_DELAY } from "../../../app-config"

import { gsap } from "gsap"

const app = "#___app"
const page = `#___content, #___footer`

/**
 * Reversible in/out animation
 */
export default function animate() {
  return gsap
    .timeline({
      defaults: {
        duration: LINK_NAVIGATE_DELAY,
        ease: "expo.in",
      },
    })
    .to(app, { opacity: 1 })
    .fromTo(page, { opacity: 0 }, { opacity: 1 }, "<")
}

/**
 * RouteUpdate animation (in)
 */
export const animateIn = () => {
  animate().play(0)
}

/**
 * WillNavigate animation (out)
 */
export const animateOut = () => {
  animate().reverse(0)
}
