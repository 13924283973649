// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// gatsby
import Anchor from "./anchor"
import Decoration from "./decoration"

// <Button />
type Props = {
  /** Create an external link */
  url?: string,
  /** Create an internal link */
  to?: string,
  /** Create a smooth scroll link */
  elementId?: string,
  /** Create an email link */
  email?: string,
  /** Create a telephone/fax link */
  telephone?: string,
  /** Create a link to an address */
  address?: string,
  /** Optional placeId when linking to an address */
  placeId?: string,
  /** match item in images array to use as image */

  image?: string | RegExp,
  /** Component data, perhaps provided by withData() */
  data?: {
    /** Image src image data */
    images: Array<{ relativePath: string, src?: string, srcSet?: string, ... }>,
  },
  /** Alternative text */
  alt: string,
  /** Human readable label */
  label?: string,
  /** Show label */
  showLabel?: boolean,
  /** Content */
  children?: any,
  /** Custom class for root element */
  className?: string,
}

/**
 * An image that is not a necessary part of the page content.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .button          | Root element                                           |
 * |------------------|--------------------------------------------------------|
 */
class ButtonComponent extends React.Component<Props> {
  // react methods

  render() {
    let {
      // anchor
      url,
      to,
      elementId,
      scroll,
      email,
      telephone,
      address,
      placeId,
      // decoration
      image,
      data,
      alt,
      // button
      label,
      showLabel,
      children,
      className,
      ...buttonProps
    } = this.props

    let anchorProps = {
      url,
      to,
      elementId,
      scroll,
      email,
      telephone,
      address,
      placeId,
    }

    let decorationProps = {
      image,
      data,
      alt,
    }

    return (
      <Anchor
        {...anchorProps}
        {...buttonProps}
        {...classNames("button")
          .plus(className)
          .plus((showLabel && label) || children ? "with-label" : "")}
        title={label}
        role="button"
      >
        {(showLabel || children) && (
          <span {...classNames("label")}>{children || label}</span>
        )}
        <Decoration
          {...decorationProps}
          alt={label}
          {...classNames("icon")}
          tag="span"
        />
      </Anchor>
    )
  }
}

/**
 * Exports
 */
export default ButtonComponent
