// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// gatsby
import { withPrefix } from "gatsby"

// <Decoration />
type Props = {
  /** wrapper tag */
  tag: string,
  /** match item in images array to use as image */
  image?: string | RegExp,
  /** Component data, perhaps provided by withData() */
  data?: {
    /** Image src image data */
    images?: Array<{
      relativePath: string,
      src?: string,
      srcSet?: string,
      ...
    }>,
  },
  /** Alternative text */
  alt: string,
  /** Custom class for root element */
  className?: string,
}

/**
 * An image that is not a necessary part of the page content.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .decoration      | Root element                                           |
 * |------------------|--------------------------------------------------------|
 */
class DecorationComponent extends React.Component<Props> {
  static defaultProps = {
    tag: "div",
  }
  // react methods

  render() {
    let { tag, image, data, alt, className, ...decorationProps } = this.props

    let src
    let srcSet
    let imageProps = Object.assign({}, decorationProps)
    let WrapperTag = tag

    if (data) {
      // if data is a list of possible images
      // get image which matches "image" prop
      if (image && data.images instanceof Array) {
        ;({ src, srcSet } =
          data.images.find((img) =>
            img.relativePath.match(
              image instanceof RegExp ? image : new RegExp(image)
            )
          ) || {})
      }
    }

    // set aria role if no alt
    if (!alt) {
      imageProps.role = "presentation"
    }

    // set error if no src
    if (image && !src) {
      console.error(`${image} not found!`)
    }

    // // handle prefixed paths on src
    // src = withPrefix(src)

    // // handle prefixed paths on srcset
    // srcSet = (srcSet || "")
    //   .split(",")
    //   .map((def) => {
    //     let [src, width] = def.split(" ")
    //     src = withPrefix(src)
    //     return width ? `${src} ${width}` : `${src}`
    //   })
    //   .join(",")

    return (
      <WrapperTag {...classNames("decoration").plus(className)}>
        <img src={src} srcSet={srcSet} alt={alt} {...imageProps} />
      </WrapperTag>
    )
  }
}

/**
 * Exports
 */
export default DecorationComponent
