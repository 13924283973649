// @flow

// react
import * as React from "react"
import ReactDOM from "react-dom"
import classNames from "react-css-module-classnames"

// third party link components
import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import * as Gatsby from "gatsby"

// utility
import Events from "../classes/events"
import triggerEvent from "../functions/trigger-event"

// compat
import getScrollTop from "../../core/compatibility/get-scroll-top"

// config
import { LINK_NAVIGATE_DELAY } from "../../../app-config"
import siteMetadata from "../../client/data/site-metadata.json"

gsap.registerPlugin(ScrollToPlugin)

// <Anchor />
type Props = {
  /** Create an external link */
  url?: string,
  /** Create an internal link */
  to?: string,
  /** Create a smooth scroll link */
  elementId?: string,
  /** Create a smooth scroll link to keyword */
  scroll?: "below-fold" | "top",
  /** Create an email link */
  email?: string,
  /** Create a telephone/fax link */
  telephone?: string,
  /** Create a link to an address */
  address?: string,
  /** Optional placeId when linking to an address */
  placeId?: string,
  /** Role */
  role?: string,
  /** Root element class name */
  className?: string,
  /** Content */
  children: any,
  ...
}

/**
 * Generates a link depending on the properties given.
 *
 * |---------------------|-----------------------------------------------------|
 * | Properties          | Link Type         | Example Value                   |
 * |---------------------|-----------------------------------------------------|
 * | url                 | External anchor   | https://google.com              |
 * | to                  | Internal anchor   | /privacy                        |
 * | email               | "mailto:" anchor  | an@email.com                    |
 * | telephone           | "tel:" anchor     | +1234567890                     |
 * | address             | maps api anchor   | 123 Fake St., New York          |
 * |---------------------|-----------------------------------------------------|
 *
 * When using the property "address", the optional property "placeId" can
 * also be supplied.
 *
 * @see <https://developers.google.com/places/place-id>
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .anchor          | Root element                                           |
 * | .external        | External link                                          |
 * | .internal        | Internal link                                          |
 * | .smoothscroll    | Smooth scroll link                                     |
 * | .formatted       | Formatted link                                         |
 * | .email           | mailto: link                                           |
 * | .telephone       | tel: link                                              |
 * | .address         | Maps api link                                          |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - [Gatsby Link API](https://www.gatsbyjs.org/docs/gatsby-link/)
 * - [Smooth Scroll Link API](https://github.com/fisshy/react-scroll)
 */
class AnchorComponent extends React.Component<Props> {
  props: Props

  static External: Class<ExternalAnchorComponent>
  static Internal: Class<InternalAnchorComponent>
  static SmoothScroll: Class<SmoothScrollAnchorComponent>
  static Formatted: Class<FormattedAnchorComponent>

  // refs

  self: React.Node | null

  // react methods

  render() {
    let {
      url,
      to,
      elementId,
      scroll,
      email,
      telephone,
      address,
      placeId,
      ...passthruProps
    } = this.props

    // url -> External
    if (typeof url !== "undefined" && url !== null) {
      //$FlowFixMe
      return <ExternalAnchorComponent url={url} {...passthruProps} />
    }

    // to -> Internal
    if (typeof to !== "undefined" && to !== null) {
      //$FlowFixMe
      if (to[0] !== "/") to = `/${to}`
      return <InternalAnchorComponent to={to} {...passthruProps} />
    }

    // elementId -> SmoothScroll
    if (typeof elementId !== "undefined" && elementId !== null) {
      return (
        //$FlowFixMe
        <SmoothScrollAnchorComponent elementId={elementId} {...passthruProps} />
      )
    }

    // scroll -> SmoothScroll
    if (typeof scroll !== "undefined" && scroll !== null) {
      return (
        //$FlowFixMe
        <SmoothScrollAnchorComponent keyword={scroll} {...passthruProps} />
      )
    }

    // anchors below this comment have a third class name
    // pick out any custom class name so it isn't lost
    let className
    ;({ className, ...passthruProps } = passthruProps)

    // email -> Formatted
    if (typeof email !== "undefined" && email !== null) {
      return (
        <FormattedAnchorComponent
          url={email}
          formatter={(email) => `mailto:${email.toLowerCase()}`}
          {...classNames("email").plus(className)}
          {...passthruProps}
        />
      )
    }

    // telephone -> Formatted
    if (typeof telephone !== "undefined" && telephone !== null) {
      return (
        <FormattedAnchorComponent
          url={telephone}
          formatter={(telephone) => `tel:${telephone.replace(/[^\d+]/g, "")}`}
          {...classNames("telephone").plus(className)}
          {...passthruProps}
        />
      )
    }

    // address -> Address
    if (typeof address !== "undefined" && address !== null) {
      return (
        <FormattedAnchorComponent
          url={address}
          formatter={(address, { placeId }) => {
            const query = encodeURIComponent(address).replace(/%20/g, "+")

            let url = `https://www.google.com/maps/search/?api=1`
            if (query) url += `&query=${query}`
            if (placeId) url += `&query_place_id=${placeId}`

            return url
          }}
          opts={{ placeId }}
          {...classNames("address").plus(className)}
          {...passthruProps}
        />
      )
    }

    // no anchor target, but role="button" = plain <button> elements
    if (this.props.role === "button") {
      return (
        <button {...classNames("button").plus(className)} {...passthruProps}>
          {this.props.children}
        </button>
      )
    }

    return <>{this.props.children}</>
  }
}

/**
 * Link to an external url that safely opens in a new window.
 */
class ExternalAnchorComponent extends React.Component<{
  /** Anchor "href" value */
  url: string,
  /** Root element class name */
  className?: string,
  /** Content */
  children: any,
}> {
  render() {
    let { url, className, children, ...passthruProps } = this.props

    if (url.match(/^(http(s)?:\/\/)/) === null) {
      console.warn("Expected anchor url to start with http:// or https://")
      url = `http://${url}` // add http prefix
    }

    return (
      <a
        href={url}
        {...classNames("external anchor").plus(className)}
        {...passthruProps}
        // ensure no data leaked to external pages
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    )
  }
}

/**
 * Link to an internal gatsby url.
 */
class InternalAnchorComponent extends React.Component<{
  /** Gatsby page path, starting with "/" */
  to: string,
  /** Root element class name */
  className?: string,
  /** Content */
  children: any,
  /** Inline onClick listener */
  onClick?: Function,
}> {
  render() {
    let { to, className, children, onClick, ...passthruProps } = this.props

    const normalize = (to) => {
      // remove pathname
      const prefix = new URL(siteMetadata.siteUrl).pathname
      to = to.replace(new RegExp(`^${prefix}`), "")

      // add first slash
      if (to.charAt(0) !== "/") {
        to = `/${to}`
      }

      // add trailing slash
      if (to.charAt(to.length - 1) !== "/") {
        to = `${to}/`
      }

      return to
    }

    return (
      <Gatsby.Link
        to={to}
        {...classNames("internal anchor").plus(className)}
        {...passthruProps}
        onClick={function (e) {
          // passthru onclick from props
          if (typeof onClick === "function") {
            onClick.call(this, e)
          }

          // links to the current page do nothing except scroll to top
          console.log("to", to, self.location.pathname)
          if (normalize(to) === normalize(self.location.pathname)) {
            triggerEvent(`willNotNavigate.drift`, { to })
            window.scrollTo(0, 0)
            e.preventDefault()
            return false
          }

          // fire willNavigate event
          triggerEvent(`willNavigate.drift`, { delay, to })

          // optionally delay navigation of internal links
          let delay = LINK_NAVIGATE_DELAY || 0
          if (delay) {
            setTimeout(() => Gatsby.navigate(this.props.to), delay * 1000)

            e.preventDefault()
            e.stopPropagation()
            return false
          }
        }.bind(this)}
      >
        {children}
      </Gatsby.Link>
    )
  }
}

/**
 * Link that scrolls to an element (by id).
 */
class SmoothScrollAnchorComponent extends React.Component<
  {
    /** id of element to scroll to, without hash sign */
    elementId?: string,
    /** keyword to scroll to, without hash sign */
    keyword?: "below-fold" | "top",
    /** Root element class name */
    className?: string,
    /** Content */
    children: any,
  },
  {
    targetOnScreen: boolean,
  }
> {
  state = {
    targetOnScreen: false,
  }

  events = new Events()

  getScrollToFromKeyword(keyword) {
    switch (keyword) {
      case "below-fold":
        return window.innerHeight
      case "top":
        return 0
    }

    throw new Error("Invalid scroll keyword")
  }

  getSelectorFromElementId(elementId) {
    if (elementId.match(/^#/) !== null) {
      console.warn("don't include a # sign in elementId")
      elementId = elementId.substring(1) // remove hash prefix
    }

    return `#${elementId}`
  }

  scrollHandler(event: Event) {
    let { elementId, keyword } = this.props

    const scrollTop = getScrollTop()
    const scrollBottom = scrollTop + window.innerHeight

    let top
    let bottom

    if (keyword) {
      top = bottom = this.getScrollToFromKeyword(keyword)
    } else if (elementId) {
      const element = document.querySelector(
        this.getSelectorFromElementId(elementId)
      )

      if (!element) {
        this.setState({ targetOnScreen: false })
        return false
      }

      let rect = element.getBoundingClientRect()
      top = rect.top + scrollTop
      bottom = rect.bottom + scrollTop
    }

    let targetOnScreen = top < scrollBottom && bottom >= scrollTop

    this.setState({ targetOnScreen })
  }

  componentDidMount() {
    this.events.listen(
      "scroll",
      this.scrollHandler.bind(this),
      window,
      {},
      { wait: 100, options: { leading: true, trailing: true } }
    )
  }

  componentWillUnmount() {
    this.events.cleanUp()
  }

  render() {
    let { elementId, keyword, className, children, ...passthruProps } =
      this.props
    let { targetOnScreen } = this.state

    return (
      <a
        href="#___app"
        onClick={(e) => {
          let scrollTo

          if (keyword) {
            scrollTo = this.getScrollToFromKeyword(keyword)
          } else if (elementId) {
            scrollTo = this.getSelectorFromElementId(elementId)
          }

          gsap.to(window, { duration: 0.5, scrollTo })
          e.preventDefault()
          e.stopPropagation()
          return false
        }}
        {...classNames("smoothscroll anchor")
          .plus(className)
          .plus(targetOnScreen ? "target-on-screen" : "")}
        {...passthruProps}
      >
        {children}
      </a>
    )
  }
}

/**
 * Link, where the url is preprocessed by a formatter() function.
 * Intended for links like tel:, mailto:, etc.
 */
class FormattedAnchorComponent extends React.Component<{
  /** unformatted value of "href" */
  url: string,
  /** formatter function */
  formatter?: (url: string, opts: Object) => string,
  /** opts to pass to formatter function */
  opts?: Object,
  /** Root element class name */
  className?: string,
  /** Content */
  children: any,
}> {
  static defaultProps = {
    opts: {},
  }

  render() {
    let { url, formatter, opts, className, children, ...passthruProps } =
      this.props

    // apply formatter
    if (typeof formatter === "function") {
      url = formatter(url, opts)
    }

    return (
      <a
        href={`${url}`}
        {...classNames("formatted anchor").plus(className)}
        {...passthruProps}
        // ensure no data leaked to external pages
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    )
  }
}

/**
 * Exports
 */
AnchorComponent.External = ExternalAnchorComponent
AnchorComponent.Internal = InternalAnchorComponent
AnchorComponent.SmoothScroll = SmoothScrollAnchorComponent
AnchorComponent.Formatted = FormattedAnchorComponent

export default AnchorComponent
