// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// components
import Anchor from "./anchor"

// <Navigation />
type Props = {
  /** Title */
  title?: String,
  /** Data */
  data?: {
    /** Image src image data */
    anchors?: Array<{
      label: any,
      /** Create an external link */
      url?: string,
      /** Create an internal link */
      to?: string,
      /** Create a smooth scroll link */
      elementId?: string,
      /** Create an email link */
      email?: string,
      /** Create a telephone/fax link */
      telephone?: string,
      /** Create a link to an address */
      address?: string,
      /** Optional placeId when linking to an address */
      placeId?: string,
      ...
    }>,
  },
  /** Content */
  children: any,
  /** Custom class for root element */
  className?: string,
  ...
}

/**
 * Navigation
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .navigation      | Root element                                           |
 * | .menu-items      | List of menu items                                     |
 * | .menu-item       | Single menu item                                       |
 * |------------------|--------------------------------------------------------|
 */
class Navigation extends React.Component<Props> {
  static defaultProps = {
    data: { anchors: [] },
  }

  // react methods

  render() {
    let { title, data, children, className, ...navProps } = this.props

    return (
      <nav {...navProps} {...classNames("navigation").plus(className)}>
        {title && <h2 {...classNames("title")}>{title}</h2>}
        <ul {...classNames("menu-items")}>
          {React.Children.map(children, (child, i) => (
            <li key={i} {...classNames("menu-item")}>
              {child}
            </li>
          ))}
          {data.anchors &&
            data.anchors.map(({ label, ...anchorProps }, i) => (
              <li key={i} {...classNames("menu-item")}>
                <Anchor {...anchorProps}>{label}</Anchor>
              </li>
            ))}
        </ul>
      </nav>
    )
  }
}

/**
 * Exports
 */
export default Navigation
