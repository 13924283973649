import gsap from "gsap"
import { CustomEase } from "gsap/CustomEase"

gsap.registerPlugin(CustomEase)

// ${hamburger} .pages-menu li,
// ${hamburger} .secondary-pages-menu li,
// ${hamburger} .address.anchor,
// ${hamburger} .credit,
// ${hamburger} .copyright

const hamburger = `#___hamburger`
const hamburgerClipWrapper = `${hamburger} .clip-wrapper`
const hamburgerContent1 = `${hamburger} .pages-menu li div`
const hamburgerContent2 = `
  ${hamburger} .secondary-pages-menu li div, 
  ${hamburger} .address.anchor div span
`
const closeButtonLines = `${hamburger} .close line`

const backgroundColor = (opacity) => `rgba(23,23,23,${opacity})`

const detectAnimationMode = () => {
  const mode = window
    .getComputedStyle(document.querySelector(hamburger), ":before")
    .getPropertyValue("content")
    .replace(/"/g, "")

  return mode || "unknown"
}

const animateInFromLeft = () => {
  return (
    gsap
      .timeline({
        defaults: {
          duration: 1,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0.066,0.404 0.094,0.69 0.252,0.838 0.444,1.018 0.818,1.001 1,1"
          ),
        },
      })
      // set initial values
      .set(hamburger, {
        display: "block",
        backgroundColor: backgroundColor(0),
      })
      .set(hamburgerClipWrapper, { width: 0, clearProps: "height" })
      .set(hamburgerContent1, {
        y: "100%",
      })
      .set(hamburgerContent2, {
        y: "100%",
      })
      .set(closeButtonLines, {
        strokeDashoffset: 43,
      })
      // wipe in from left
      .to(hamburgerClipWrapper, { duration: 2, width: "100%" }, 0)
      // fade out background
      .to(
        hamburger,
        {
          backgroundColor: backgroundColor(0.6),
        },
        0
      )
      // animate in close button lines
      .to(
        closeButtonLines,
        {
          strokeDashoffset: 0,
          duration: 0.25,
          ease: "expo-out",
          stagger: 0.125,
        },
        0.5
      )
      // animate in content
      .to(
        hamburgerContent1,
        {
          y: "0%",
          duration: 1,
          ease: "expo.out",
        },
        0.5
      )
      .to(
        hamburgerContent2,
        {
          y: "0%",
          duration: 1,
          ease: "expo.out",
        },
        0.7
      )
  )
}

const animateOutToLeft = () => {
  return gsap
    .timeline({
      defaults: {
        duration: 1,
        ease: "expo.out",
      },
    })
    .to(hamburger, {
      display: "none",
      backgroundColor: backgroundColor(0),
    })
    .to(hamburgerClipWrapper, { width: 0 }, "<")
}

const animateInFromTop = () => {
  return (
    gsap
      .timeline({
        defaults: {
          duration: 1,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0.066,0.404 0.094,0.69 0.252,0.838 0.444,1.018 0.818,1.001 1,1"
          ),
        },
      })
      // set initial values
      .set(hamburger, {
        display: "block",
        backgroundColor: backgroundColor(0),
      })
      .set(hamburgerClipWrapper, { height: 0, clearProps: "width" })
      .set(hamburgerContent1, {
        y: "100%",
      })
      .set(hamburgerContent2, {
        y: "100%",
      })
      .set(closeButtonLines, {
        strokeDashoffset: 43,
      })
      // wipe in from left
      .to(hamburgerClipWrapper, { duration: 2, height: "100%" }, 0)
      // fade out background
      .to(
        hamburger,
        {
          backgroundColor: backgroundColor(0.6),
        },
        0
      )
      // animate in close button lines
      .to(
        closeButtonLines,
        {
          strokeDashoffset: 0,
          duration: 0.25,
          ease: "expo-out",
          stagger: 0.125,
        },
        0.5
      )
      // animate in content
      .to(
        hamburgerContent1,
        {
          y: "0%",
          duration: 1,
          ease: "expo.out",
        },
        0.5
      )
      .to(
        hamburgerContent2,
        {
          y: "0%",
          duration: 1,
          ease: "expo.out",
        },
        0.7
      )
  )
}

const animateOutToTop = () => {
  return gsap
    .timeline({
      defaults: {
        duration: 1,
        ease: "expo.out",
      },
    })
    .to(hamburger, {
      display: "none",
      backgroundColor: backgroundColor(0),
    })
    .to(hamburgerClipWrapper, { height: 0 }, "<")
}

/**
 * Reversible in/out animation
 */
export default null

/**
 * Open animation (in)
 */
export const animateIn = () => {
  switch (detectAnimationMode()) {
    default:
    case "animate-left":
      return animateInFromLeft()

    case "animate-top":
      return animateInFromTop()
  }
}

/**
 * Close animation (out)
 */
export const animateOut = () => {
  switch (detectAnimationMode()) {
    default:
    case "animate-left":
      return animateOutToLeft()

    case "animate-top":
      return animateOutToTop()
  }
}
