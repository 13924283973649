function getScrollHeight(target) {
  if (!target) {
    console.log(
      "got scroll height",
      document.documentElement.getS || document.body.scrollHeight
    )
    return document.documentElement.scrollHeight || document.body.scrollHeight
  }

  if (!(target instanceof HTMLElement)) {
    throw new Error("Invalid target for scrollHeight()")
  }

  return target.scrollHeight
}

export default getScrollHeight
