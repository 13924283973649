import ButtonComponent from "../../core/components/button"
import withData from "../../core/composers/data"
import useImagesDirect from "../../client/queries/images-direct"

/**
 * Compose
 */
const Button = withData(
  ButtonComponent,
  ({ metadata, imagesDirect }) => ({
    images: imagesDirect,
  }),
  useImagesDirect
)

export default Button
