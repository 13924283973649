import React from "react"

/**
 * withData()
 *
 * Connect a component to data sources with an optional formatter function.
 *
 * const source = function () {
 *   // source functions should return an object
 *   // with a single property, which acts as an identifier for the data
 *   return {sourceName: data};
 * }
 *
 * // Commonly, data sources need to be formatted to meet component requirements
 * // eg. a source might return { imagesJSON: data }
 * // but the component expects { images: data }
 * // in this case, a function like ({imagesJSON: images}) => ({images})
 * // is recommended.
 * const formatter = function (concatedDataFromAllSources) {
 *   // transform the data to match the requirements of the component
 *   return concatedDataFromAllSources
 * }
 *
 * const Enhanced = withData(class extends React.component {
 *   render () {
 *      const {data} = this.props;
 *      // ...
 *   }
 * }, formatter, source);
 */
const withData = function (WrappedComponent, formatter, ...sources) {
  // must be function component to use React Hooks
  return (props) => {
    // get data from props
    const { data: passthruData, ...passthruProps } = props
    let data = Object.assign({}, passthruData)

    // add data from sources
    for (let i = 0; i < sources.length; i++) {
      let concatData = sources[i]
      if (typeof concatData === "function") concatData = concatData()

      Object.assign(data, concatData)
    }

    // format data
    if (typeof formatter === "function") {
      data = formatter(data)
    }

    // add to wrapped component
    return <WrappedComponent {...passthruProps} data={data} />
  }
}

/**
 * Exports
 */
export default withData
