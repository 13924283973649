/**
 * App Config
 */

/**
 * Initial load screen minimum display time (seconds)
 * Set to 0 for no initial loader animation
 * @see ssr-initial-loader.js
 */
exports.INITIAL_LOADER_MIN_DISPLAY_SECONDS = 0

/**
 * Amount by which to reduce opacity per animation frame when fading out
 * initial load screen.
 * Set to 1 for no fade out animation
 * @see ssr-initial-loader.js
 */
exports.INITIAL_LOADER_FADEOUT_SPEED = 1

/**
 * Time to wait before showing a loading state when making requests (seconds)
 * @see loader.js
 */
exports.LOADER_MIN_DELAY_SECONDS = 5

/**
 * Time to wait until navigation starts
 * Useful for animating page outros
 * @see anchor.js
 */
exports.LINK_NAVIGATE_DELAY = 0.5

/**
 * Client Routes
 * [ Filename, URL Path ]
 * eg.
 * // route-name.jsx -> /route-name/*
 * ["route-name", "route-name"],
 */
exports.CLIENT_ROUTES = new Map([
  // sign-in.jsx -> /sign-in/*
  ["sign-in", "sign-in"],
])

/**
 * Rewrite Routes
 * [ Match Route, New Route ]
 * eg.
 * [" old-route-name", "new-route-name"],
 */
exports.REWRITE_ROUTES = new Map([
  ["portfolio-27-star-island", "portfolio/27-star-island"],
  ["portfolio-auberge-resort", "portfolio/auberge-resort"],
  ["portfolio-breakers-resort", "portfolio/breakers-resort"],
  ["portfolio-johns-island", "portfolio/johns-island"],
  ["portfolio-manhattan", "portfolio/manhattan"],
  ["portfolio-polyner", "portfolio/polyner"],
  ["portfolio-porsche-lobby", "portfolio/porsche-lobby"],
  ["portfolio-porsche-tower", "portfolio/porsche-tower"],
  ["portfolio-rosewood", "portfolio/rosewood"],
  ["portfolio-whotel-austin", "portfolio/whotel-austin"],
  ["portfolio-windsor", "portfolio/windsor"],
  ["product-inspire-free-standing", "products/inspire/free-standing"],
  ["product-inspire-wall-mounted", "products/inspire/wall-mounted"],
  ["product-premier", "products/premier"],
  ["product-distinct", "products/distinct"],
  ["product-custom-build", "products/custom-build"],
])
