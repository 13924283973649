function getScrollTop(target) {
  if (!target) {
    console.log(
      "got scroll top",
      document.documentElement.scrollTop || document.body.scrollTop
    )
    return document.documentElement.scrollTop || document.body.scrollTop
  }

  if (!(target instanceof HTMLElement)) {
    throw new Error("Invalid target for scrollTop()")
  }

  return target.scrollTop
}

export default getScrollTop
