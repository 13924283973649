import React from "react"
import Anchor from "../../core/components/anchor"
import Navigation from "../../core/components/navigation"

export const address = (
  <Anchor address={["3613 Mayland CT", "Henrico", "VA", "23233"]}>
    <div>
      <span>3613 Mayland CT,</span>
    </div>
    <div>
      <span>Henrico, VA 23233</span>
    </div>
  </Anchor>
)

export const email = (
  <Anchor email="info@invinitywinerack.com">info@invinitywinerack.com</Anchor>
)

export const supportEmail = (
  <Anchor email="support@invinitywinerack.com">
    support@invinitywinerack.com
  </Anchor>
)

export const telephone = <Anchor telephone="855-378-1004">855-378-1004</Anchor>

export const fax = <Anchor telephone="855-378-1004">855-378-1004</Anchor>

const contactDetails = (
  <Navigation title="Invinity" className="contact-details">
    {telephone}
    {address}
    {email}
  </Navigation>
)

export default contactDetails
