/**
 * useMetadata()
 *
 * Query the config in `/app/gatsby-config.js`.
 */

/**
 * Imports
 */

// gatsby
import { useStaticQuery, graphql } from "gatsby"

/**
 * MetadataQuery
 */
function useMetadata() {
  const data = useStaticQuery(graphql`
    query MetadataQuery {
      site {
        siteMetadata {
          clientName
          lang
          title
          slug
          description
          siteUrl
          themeColor
          faviconURL
        }
      }
    }
  `)

  return { metadata: data.site.siteMetadata }
}

/**
 * Exports
 */
export default useMetadata
