// libraries
import React from "react"
import { Helmet } from "react-helmet"

// events
import { listen, cleanUp } from "../../../app-events"

// data
import metadata from "../../client/data/site-metadata.json"

// styles
import styles from "../../client/themes/default/main.scss"

// utility
import Events from "../../core/classes/events"
import isSSR from "../../core/functions/is-ssr"
import triggerEvent from "../../core/functions/trigger-event"

// compat
import getScrollHeight from "../../core/compatibility/get-scroll-height"
import getScrollTop from "../../core/compatibility/get-scroll-top"

/**
 * The app events component
 */
class AppEventListeners extends React.Component {
  componentDidMount() {
    listen()
  }

  componentWillUnmount() {
    cleanUp()
  }

  render() {
    return null
  }
}

/**
 * Calculate global values/states
 */
class AppGlobalValues extends React.Component {
  state = {
    scrollTop: null,
  }

  events = new Events()

  // make actual vw/vh available in css
  calculateVwVh() {
    const doc = document.documentElement || document.body

    const vw = window.innerWidth * 0.01
    const vh = window.innerHeight * 0.01

    doc.style.setProperty("--vw", `${vw}px`)
    doc.style.setProperty("--vh", `${vh}px`)
  }

  // apply body class dependant on scroll behaviour
  handleScroll() {
    this.setState({ scrollTop: getScrollTop() })
  }

  applyScrollClassNames(scrollTop, prevScrollTop) {
    const classes = document.body.classList

    const minScroll = 0
    const maxScroll = getScrollHeight() - window.innerHeight
    const fold = window.innerHeight

    // at top
    let atTopClassName = "scroll-at-top"
    if (scrollTop <= minScroll) {
      classes.add(atTopClassName)
    } else {
      classes.remove(atTopClassName)
    }

    // above the fold
    let aboveFoldClassName = "scroll-above-fold"
    if (scrollTop < fold) {
      classes.add(aboveFoldClassName)
    } else {
      classes.remove(aboveFoldClassName)
    }

    // below the fold
    let belowFoldClassName = "scroll-below-fold"
    if (scrollTop > fold) {
      classes.add(belowFoldClassName)
    } else {
      classes.remove(belowFoldClassName)
    }

    // at bottom
    let atBottomClassName = "scroll-at-bottom"
    if (scrollTop >= maxScroll) {
      classes.add(atBottomClassName)
    } else {
      classes.remove(atBottomClassName)
    }

    // going up
    let goingUpClassName = "scrolled-up"
    if (scrollTop < prevScrollTop) {
      classes.add(goingUpClassName)
    } else {
      classes.remove(goingUpClassName)
    }

    // going Down
    let goingDownClassName = "scrolled-down"
    if (scrollTop > prevScrollTop) {
      classes.add(goingDownClassName)
    } else {
      classes.remove(goingDownClassName)
    }
  }

  componentDidMount() {
    const { events, calculateVwVh, handleScroll } = this

    requestAnimationFrame(() => {
      triggerEvent("rootWrapperMounted.drift", {})
    })

    // calc vw/vh
    calculateVwVh.call(this)
    events.listen("resize", calculateVwVh.bind(this))

    // handle scroll
    handleScroll.call(this)
    events.listen(
      "scroll",
      handleScroll.bind(this),
      window,
      { passive: true },
      { wait: 10, options: { leading: true, trailing: true } }
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollTop: prevScrollTop } = prevState
    const { scrollTop } = this.state

    if (scrollTop !== prevScrollTop) {
      this.applyScrollClassNames(scrollTop, prevScrollTop)
    }
  }

  componentWillUnmount() {
    const { events } = this
    this.events.cleanUp()
  }

  render() {
    return null
  }
}

/**
 * The global root wrapper.
 */
export default function RootWrapper({ element, props }) {
  const { ...passthruProps } = props

  if (!isSSR()) {
    requestAnimationFrame(() => {
      triggerEvent("rootWrapperRender.drift", {})
    })
  }

  return (
    <div id="___app" {...passthruProps}>
      <AppEventListeners />
      <AppGlobalValues />
      {element}
    </div>
  )
}
