// libraries
import React from "react"
import { Helmet } from "react-helmet"

// data
import metadata from "../../client/data/site-metadata.json"

// elements
import { address, email } from "../../client/elements/contact-details"
import header from "../../client/elements/header"
import hamburger from "../../client/elements/hamburger"
import footer from "../../client/elements/footer"

// utility
import isSSR from "../../core/functions/is-ssr"
import triggerEvent from "../../core/functions/trigger-event"

/**
 * The global page wrapper.
 */
export default function PageWrapper({ element, props }) {
  const {
    location,
    navigate,
    pageContext,
    pageResources,
    path,
    pathContext,
    uri,
    ...passthruProps
  } = props

  if (!isSSR()) {
    requestAnimationFrame(() => {
      triggerEvent("pageWrapperRender.drift", {
        location,
        pageContext,
        uri,
        pageResources,
      })
    })
  }

  return (
    <section id="___page">
      <Helmet
        titleTemplate={`%s | ${metadata.title}`}
        defaultTitle={metadata.title}
      >
        <html lang={metadata.lang} />
        <meta name="description" content={metadata.description} />
      </Helmet>
      {header}
      {hamburger}
      <main id="___content" {...passthruProps}>
        {element}
      </main>
      {footer}
    </section>
  )
}
