/**
 * Display an initial loading screen.
 *
 * We can't achieve this with a traditional react component, because we want to
 * back it into the SSR. The idea is to progressively enhance the loading
 * screen as soon as code loads:
 *
 * 1. Fixed white `#___loader` element is baked into `html.js`
 * 2. Styles applied to element once loaded
 * 3. Animation in `./client/timelines/initial-loader` runs onClientEntry
 * 4. Loader is removed after animation ends, or load tasks are done, whichever *    happens later.
 */

import React from "react"
import { title } from "./client/data/site-metadata.json"
import createFlag from "./core/functions/create-flag"
import initialLoaderTimeline, {
  animateIn,
  animateOut,
} from "./client/timelines/initial-loader"

// config
import {
  INITIAL_LOADER_MIN_DISPLAY_SECONDS,
  INITIAL_LOADER_FADEOUT_SPEED,
} from "../app-config"

// app logo
import appLogoSrc from "../src/client/assets/images/app-logo.svg"

// flags
const animatedFlag = createFlag("isAnimated")
const loadedFlag = createFlag("isLoaded")

/**
 * Loading screen component
 */
function SSRInitialLoaderComponent() {
  if (!INITIAL_LOADER_MIN_DISPLAY_SECONDS) {
    return null
  }

  return (
    <div
      key={`loader`}
      id="___loader"
      style={{
        backgroundColor: "white",
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {appLogoSrc ? (
        <img src={appLogoSrc} alt={title || ""} />
      ) : title ? (
        `Loading ${title}...`
      ) : (
        `Loading...`
      )}
    </div>
  )
}

/**
 * Set/detect animation state
 */
export function isAnimated(state) {
  return animatedFlag(getLoaderElement(), state)
}

/**
 * Set/detect load state
 */
export function isLoaded(state) {
  return loadedFlag(getLoaderElement(), state)
}

/**
 * Animate the loading screen
 * called by gatsby-browser.js onClientEntry
 */
export function animate() {
  const element = getLoaderElement()
  if (!element) return

  // load custom snimation timeline
  if (typeof initialLoaderTimeline === "function") {
    initialLoaderTimeline({
      onStart: startAnimation,
      onComplete: endAnimation,
      duration: INITIAL_LOADER_MIN_DISPLAY_SECONDS,
    })

    return
  }

  // fallback to default animation
  startAnimation()

  setTimeout(() => {
    endAnimation()
  }, INITIAL_LOADER_MIN_DISPLAY_SECONDS * 1000)
}

/**
 * Start animation
 */
export function startAnimation() {
  const element = getLoaderElement()
  if (!element) return

  isAnimated(true)

  if (typeof animateIn === "function") {
    animateIn(element)
  }
}

/**
 * End animation
 */
export function endAnimation() {
  const element = getLoaderElement()
  if (!element) return

  isAnimated(false)

  if (typeof animateOut === "function") {
    animateOut(element, {
      duration: INITIAL_LOADER_FADEOUT_SPEED,
      onComplete: removeLoaderElement,
    })
    return
  }

  // default fade out animation
  let opacity = parseFloat(element.style.opacity, 10)

  if (isNaN(opacity)) {
    opacity = 1
  }

  if (opacity > 0) {
    requestAnimationFrame(() => {
      element.style.opacity = opacity - (INITIAL_LOADER_FADEOUT_SPEED || 1)
      endAnimation()
    })
    return
  }

  removeLoaderElement()
}

/**
 * End load
 */
export function endLoad() {
  const element = getLoaderElement()
  if (!element) return

  isLoaded(true)
  removeLoaderElement()
}

/**
 * Return the loader element
 */
function getLoaderElement() {
  return document.querySelector("#___loader")
}

/**
 * Hide the loading screen if not animated
 * called by gatsby-browser.js onInitialClientRender
 */
function removeLoaderElement() {
  const element = getLoaderElement()
  if (!element) return

  if (!isAnimated() && isLoaded()) {
    element.parentNode.removeChild(element)
  }
}

export default SSRInitialLoaderComponent
