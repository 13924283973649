// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// gatsby
import Anchor from "./anchor"

// utility
import queryData from "../functions/query-data"
import stringToSlug from "../functions/string-to-slug"
import { withPrefix } from "gatsby-link"

// <SiteLogo />
type Props = {
  /** Component data, perhaps provided by withData() */
  data: {
    /** Site metadata */
    site: {
      /** Site title */
      title: string,
      ...
    },
    /** Array of images for component to reference */
    images: Array<{
      /** Image name (referenced by component) */
      name: string,
      /** Image element */
      element: React.Node | Element,
      ...
    }>,
  },
  /**
   * Component title/alternative text
   * If not given, data.site.title is used instead.
   */
  title: string,
  /** Logo link target */
  to: "/" | null,
  /** Custom logo element */
  logo?: React.Element<"img" | "svg"> | null,
  /** The logo image name in data.images, ignored if "logo" is set */
  image?: string | null,
  /** Custom class for root element */
  className?: string,
  ...
}

/**
 * Site logo
 *
 * A site logo consists of both a title (probably hidden except for screen
 * readers) and an image. An img/svg element can be supplied directly in the
 * "logo" property, or an image name given in "image" property.
 *
 * Site logos are always linked to the homepage by convention.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .site-logo       | Root element                                           |
 * |------------------|--------------------------------------------------------|
 */
class SiteLogo extends React.Component<Props> {
  static defaultProps = {
    title: "",
    to: "/",
    image: "logomark",
    data: {
      site: {},
      images: [],
    },
  }

  // react methods

  render() {
    let { data, title, to, logo, image, className, ...anchorProps } = this.props

    // get title
    if (!title && data.site) {
      title = data.site.title
    }

    // get logo
    if (!logo && data.images) {
      logo = queryData(data.images).getOne("element").where("name").is(image)
    }

    // add custom props to logo
    if (logo) {
      logo = React.cloneElement(logo, {
        alt: `${title} Logo`,
      })
    }

    return (
      <h1
        {...classNames("site-logo")
          .plus(image && stringToSlug(image))
          .plus(className)}
      >
        <Anchor to={to} aria-label={title} title={title} {...anchorProps}>
          {logo}
        </Anchor>
      </h1>
    )
  }
}

/**
 * Exports
 */
export default SiteLogo
