function getIdleCallback(target) {
  let idleCallback

  if (typeof requestIdleCallback === "function") {
    idleCallback = requestIdleCallback
  } else if (typeof requestAnimationFrame === "function") {
    idleCallback = requestAnimationFrame
  } else {
    idleCallback = (...args) => setTimeout(args[0], 0)
  }

  return idleCallback
}

export default getIdleCallback
