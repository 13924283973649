/**
 * Create a function that sets/gets a flag on an element dataset.
 */
function createFlag(flag) {
  flag = flag.toLowerCase() // attribute names are lowercase

  return (element, state) => {
    // setter
    if (typeof state === "boolean") {
      state && element.setAttribute(`data-${flag}`, true)
      !state && element.removeAttribute(`data-${flag}`)
    }

    // getter
    return !!element.dataset[flag]
  }
}

export default createFlag
