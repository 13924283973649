import NewsletterComponent from "../../core/components/newsletter"

import addToMailchimp from "gatsby-plugin-mailchimp"
import withHandler from "../../core/composers/handler"

/**
 * Compose
 */
const Newsletter = withHandler(
  NewsletterComponent,
  async (request) => {
    const { result, msg } = await request

    if (result === "error") {
      console.log(msg)
      return false
    }

    return true
  },
  ({ data }) => {
    return addToMailchimp(data.email)
  }
)

export default Newsletter
