// @flow

import React from "react"
import classNames from "react-css-module-classnames"

import callWhenIdle from "../functions/call-when-idle"

// <Newsletter />
type Props = {
  /** Label */
  label?: string,
  /** Submit */
  submit?: any,
  /** Form handler */
  handler: Function,
  /** Handle invalid submit */
  onHandler?: Function,
  /** Placeholder for input(s) */
  placeholder?: string,
  /** Content */
  children?: any,
  /** Root element class name */
  className?: string,
}

type State = {
  valid: boolean,
  submitted: boolean,
}

/**
 * Generates a newsletter sign-up form.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .newsletter      | Root element                                           |
 * | .enter-email     | Email field                                            |
 * | .title           | Input label text                                       |
 * | .email           | Field email input                                      |
 * | .submit          | Form submit button                                     |
 * |------------------|--------------------------------------------------------|
 */
class NewsletterComponent extends React.Component<Props, State> {
  static defaultProps = {
    label: "Sign up to our newsletter",
    placeholder: "Email address",
  }

  state = {
    valid: false,
    submitted: false,
    msg: "",
  }

  formElement: ?HTMLFormElement

  getEmail() {
    const { formElement } = this

    if (!formElement) {
      return ""
    }

    return new FormData(formElement).get("email")
  }

  setEmail(email) {
    const { formElement } = this

    if (!formElement) {
      return ""
    }

    formElement.querySelector("*[type=email]").value = email
  }

  async callHandler() {
    const { handler, onHandler } = this.props
    const { submitted } = this.state
    const email = this.getEmail()

    if (typeof handler !== "function") {
      throw new Error("No handler defined for newsletter")
    }

    const handlerResult = await handler.call(this, {
      data: {
        email,
      },
    })
    const valid = !!handlerResult
    const msg = valid ? "" : "Something went wrong, try again."

    this.setState({ valid, submitted: true, msg }, () => {
      if (valid) {
        this.setEmail("")
      }
    })

    // trigger handler callback if necessary
    if (typeof onHandler === "function") {
      await onHandler.call(this, email, handlerResult)
    }
  }

  /** Call handler with form values */
  async handleSubmit(event: Event) {
    this.callHandler()

    if (event) {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }

  // react methods

  render() {
    let {
      label,
      submit,
      handler,
      onHandler,
      placeholder,
      children,
      className,
      ...passthruProps
    } = this.props
    let { email, valid, submitted, msg } = this.state

    let emailField = (
      <input
        type="email"
        name="email"
        placeholder={placeholder}
        {...classNames("email")}
      />
    )

    let submitButton

    switch (typeof submit) {
      case "undefined":
        submitButton = (
          <button type="submit" {...classNames("submit")}>
            Submit
          </button>
        )
        break

      case "string":
        submitButton = (
          <button type="submit" {...classNames("submit")}>
            {submit}
          </button>
        )
        break

      default:
        submitButton = submit
        break
    }

    return (
      <form
        ref={(formElement) => (this.formElement = formElement)}
        onSubmit={this.handleSubmit.bind(this)}
        {...classNames("newsletter").plus(className)}
        {...passthruProps}
      >
        <fieldset>
          <p
            {...classNames("enter-email")
              .plus(valid && "valid")
              .plus(submitted && "submitted")}
          >
            {label ? (
              <label>
                <span {...classNames("title")}>{label}</span> {emailField}
              </label>
            ) : (
              emailField
            )}
          </p>
          {submitButton}
        </fieldset>
        {submitted && <output>{msg || children}</output>}
      </form>
    )
  }
}

export default NewsletterComponent
