import throttle from "lodash/throttle"

class Events {
  /**
   * Constructor
   */
  constructor() {
    this.listeners = []

    this.listen = this.listen.bind(this)
    this.cleanUp = this.cleanUp.bind(this)
  }

  /**
   * Listen to an event
   */
  listen(
    eventName,
    handler,
    target = window,
    options = {},
    throttleOptions = false
  ) {
    if (typeof eventName !== "string") {
      throw new Error("Invalid eventName")
    }

    if (typeof handler !== "function") {
      throw new Error("Invalid handler")
    }

    if (!(target instanceof EventTarget)) {
      throw new Error("Invalid event target")
    }

    if (typeof options !== "object") {
      throw new Error("Invalid eventName")
    }

    if (typeof throttle === "object") {
      handler = throttle(
        handler,
        throttleOptions.wait || 0,
        throttleOptions.options || {}
      )
    }

    target.addEventListener(eventName, handler, options)

    this.listeners.push({
      target,
      eventName,
      handler,
    })

    console.log("listen", eventName, handler)
  }

  /**
   * Remove all listeners
   */
  cleanUp() {
    this.listeners.forEach(({ target, eventName, handler }) => {
      target.removeEventListener(eventName, handler)
    })

    this.listeners = []
  }
}

export default Events
