import React from "react"
import Anchor from "../../core/components/anchor"
import Navigation from "../../core/components/navigation"
import {
  address,
  email,
  telephone,
} from "../../client/elements/contact-details"
import socialNetworks from "../../client/data/social-networks.json"

export const pagesMenu = (
  <Navigation className="pages-menu">
    <div>
      <span className="counter">01</span>
      <Anchor to="about">About</Anchor>
    </div>
    <div>
      <span className="counter">02</span>
      <Anchor to="products">Products</Anchor>
    </div>
    <div>
      <span className="counter">03</span>
      <Anchor to="portfolio">Portfolio</Anchor>
    </div>
    <div>
      <span className="counter">04</span>
      <Anchor to="contact">Contact</Anchor>
    </div>
  </Navigation>
)

export const secondaryPagesMenu = (
  <Navigation className="secondary-pages-menu">
    <div>
      <Anchor to="faq">FAQ</Anchor>
    </div>
    <div>
      <Anchor to="warranty">Warranty</Anchor>
    </div>
    <div>
      <Anchor to="resources">Resources</Anchor>
    </div>
  </Navigation>
)

export const quickMenu = (
  <Navigation className="quick-menu">
    <Anchor to="products">Products</Anchor>
    <Anchor to="portfolio">Portfolio</Anchor>
    <Anchor to="contact">Contact</Anchor>
    <Anchor to="resources">Resources</Anchor>
  </Navigation>
)

export const supportMenu = (
  <Navigation title="Orders & Support" className="support-menu">
    <Anchor to="faq">FAQ</Anchor>
    <Anchor to="warranty">Warranty</Anchor>
    <Anchor to="resources">Resources</Anchor>
    <Anchor to="contact" className="not-mobile-only">
      Contact
    </Anchor>
    <Anchor to="privacy" className="mobile-only">
      Privacy
    </Anchor>
    <Anchor to="terms" className="mobile-only">
      Terms and Conditions
    </Anchor>
  </Navigation>
)

export const contactMenu = (
  <Navigation title="Contact Us" className="contact-menu">
    {address}
    {email}
    {telephone}
  </Navigation>
)

export const socialNetworksMenu = (
  <Navigation className="social-networks-menu">
    {socialNetworks.map(({ title, url }) => (
      <Anchor url={url} key={url}>
        {title}
      </Anchor>
    ))}
  </Navigation>
)

export const termsMenu = (
  <Navigation className="terms-menu not-mobile-only">
    <Anchor to="privacy">Privacy</Anchor>
    <Anchor to="terms">Terms and Conditions</Anchor>
  </Navigation>
)
