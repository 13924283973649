// utility
const Events = require("./src/core/classes/events").default
const events = new Events()

// timelines
const {
  animateOut: hamburgerAnimateOut,
} = require("./src/client/timelines/hamburger")

const {
  animateIn: contentAnimateIn,
  animateOut: contentAnimateOut,
} = require("./src/client/timelines/page-transition")

/**
 * Attach listeners
 */
exports.listen = () => {
  /**
   * SSR Initial Loader
   */
  const {
    animate: animateSSRLoaderComponent,
    endLoad: endLoadSSRLoaderComponent,
  } = require("./src/ssr-initial-loader")

  events.listen("afterClientEntry.gatsby", animateSSRLoaderComponent)
  events.listen("afterInitialClientRender.gatsby", endLoadSSRLoaderComponent)

  /**
   * LazyLoader
   */
  // Tell the lazyloader to load components after route update
  const { forceCheck } = require("react-lazyload")
  events.listen("afterRouteUpdate.gatsby", () => {
    // the "correct" way
    forceCheck()

    // the way that actually works
    window.dispatchEvent(new Event("resize"))
  })

  /**
   * Hamburger menu
   */
  // Hamburger menu is always hidden on navigation
  events.listen("willNotNavigate.drift", hamburgerAnimateOut)
  events.listen("willNavigate.drift", hamburgerAnimateOut)

  /**
   * Navigate animation
   */
  events.listen("preRouteUpdate.gatsby", () => {
    // fix content flash on forward/back browser buttons
    document.querySelector("#___content").style.opacity = 0
  })
  events.listen("willNavigate.drift", contentAnimateOut)
  events.listen("afterRouteUpdate.gatsby", contentAnimateIn)
}

/**
 * Clean up listeners
 */
exports.cleanUp = events.cleanUp
