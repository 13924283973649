import SiteLogoComponent from "../../core/components/site-logo"
import withData from "../../core/composers/data"
import useImagesDirect from "../../client/queries/images-direct"
import useMetadata from "../../client/queries/metadata"
import by from "../../core/functions/by"

/**
 * Compose
 */
const SiteLogo = withData(
  SiteLogoComponent,
  ({ imagesDirect, metadata }) => ({
    images: imagesDirect.filter(by("relativePath", /\/branding\//)),
    site: metadata,
  }),
  useImagesDirect,
  useMetadata
)

export default SiteLogo
