import React from "react"
import Button from "../../client/compositions/button"
import SiteLogo from "../../client/compositions/site-logo"
import { socialNetworksMenu } from "../../client/elements/menus"
import { forceCheck } from "react-lazyload"
import { animateIn } from "../../client/timelines/hamburger"

const header = (
  <header id="___header">
    <SiteLogo image="logotype" />
    <Button
      image="hamburger.svg"
      label="Open Menu"
      className="hamburger"
      onClick={() => {
        animateIn()
        forceCheck()
      }}
    />
    {socialNetworksMenu}
  </header>
)

export default header
