import React from "react"
import Button from "../../client/compositions/button"
import SiteLogo from "../../client/compositions/site-logo"
import Newsletter from "../../client/compositions/newsletter"
import {
  supportMenu,
  contactMenu,
  termsMenu,
} from "../../client/elements/menus"

const footer = (
  <footer id="___footer">
    <div className="inner">
      {supportMenu}
      {contactMenu}
      {termsMenu}

      <Newsletter
        label="I would like to receive communications about Invinity products, services, events and matters of cultural interest."
        submit={
          <Button type="submit" image="icons/arrow-right.svg" label="Submit" />
        }
      >
        Thanks for signing up!
      </Newsletter>

      <div className="actions">
        <Button scroll="top" image="icons/arrow-right.svg">
          Top
        </Button>
      </div>

      <SiteLogo image="logomark" />
    </div>
  </footer>
)

export default footer
